/*
 * Navbar
 */

const logo = document.getElementById("logo");
const header = document.getElementById("header");
const main = document.getElementById("main");

const resizeObserver = new ResizeObserver(entries => 
    main.style.marginTop = header.clientHeight+"px"
);
resizeObserver.observe(header)

if(logo.classList.contains("large")) {
    document.addEventListener("scroll", resizeNavbar);
    header.addEventListener("resize", () => {
        main.style.marginTop = header.clientHeight+"px";
    })
} else {
    header.classList.add("bgWhite");
}

function resizeNavbar() {
    if(window.scrollY) {
        logo.classList.remove("large");
        logo.classList.add("small");
        header.classList.add("bgWhite");   
    } else {
        logo.classList.add("large");
        logo.classList.remove("small");
        header.classList.remove("bgWhite");
    }
}

/*
 * Taches
 */
import { animate,scroll } from "motion"
  
scroll(animate("#blue", {transform: "translate(-15%, 0%)"}));
scroll(animate("#yellow", {transform: "translate(12%, -8%)"}));
scroll(animate("#pink", {transform: "translate(-9%, -20%)"}));

/*
 * Menu 
 */

const menu = document.getElementById("menu");
const leftNav = document.getElementById("leftNav");
const bgBlack = document.getElementById("bg-black");
menu.addEventListener("click", function() {
    leftNav.style.left = 0;
    bgBlack.style.display = 'block';
});
bgBlack.addEventListener("click", function() {
    leftNav.style.left = "-250px";
    bgBlack.style.display = 'none';
});
window.addEventListener("resize", function() {
    leftNav.style.left = "-250px";
    bgBlack.style.display = 'none';
});